const Home = () => {
    return (
        <div>
            <div className="home">
                <div class="container">
                    <div class="row align-items-center my-5">
                        <div class="col-lg-7">
                            <img
                                class="img-fluid rounded mb-4 mb-lg-0"
                                src="https://play-lh.googleusercontent.com/cqVEnwlBCCHkIkokSCB8RX1SNhB7V8Z8ALeZmYSs-CsNNiihjULS6WOrDQrvOzDLwFo"
                                alt=""
                            />
                        </div>
                        <div class="col-lg-5">
                            <h1 class="font-weight-light">Home</h1>
                            <h1>
                                Welcome here...
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home